import React, { useState, useEffect, useRef } from 'react';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { Container, Typography, TextField, IconButton, List, ListItem, Snackbar, CssBaseline } from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';
import { Alert } from '@mui/material';
import io from 'socket.io-client';
import { createGlobalStyle } from 'styled-components';

// Global styles to ensure the entire screen has the background color
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap'); // Keep existing font
  @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap');

  body, html {
    background: linear-gradient(to bottom, #000000, #7F8C8D); 
    margin: 0;
    height: 100%;
    font-family: 'Raleway', sans-serif; // Default font for body
  }
`;

// Create theme
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1E1E1E', // Shiny charcoal
    },
    secondary: {
      main: '#818589', // Electric blue
    },
    background: {
      default: '#121212', // Slightly off-black
      paper: '#1E1E1E', // Shiny charcoal
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#B0B0B0',
    },
  },
  typography: {
    fontFamily: 'Raleway, Arial, sans-serif', // Update to Raleway font
    h4: {
      fontWeight: 700,
      fontSize: '24px',
    },
    body1: {
      fontSize: '16px',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
});

// Styled components
const PromptContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const PromptRow = styled('div')`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const Prompt = styled('div')`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const ProfilePictureContainer = styled('div')`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin: 20px auto;
`;

const ProfileImage = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TypingDots = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  & > span {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 5px;
    background-color: ${props => props.theme.palette.text.primary};
    border-radius: 50%;
    animation: blink 1.4s infinite both;
  }

  & > span:nth-child(1) {
    animation-delay: 0s;
  }

  & > span:nth-child(2) {
    animation-delay: 0.2s;
  }

  & > span:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
`;

const Header = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  background: 'linear-gradient(to right, #202020, #3F3F3F, #202020)',
  borderRadius: '50px', // Change to make it ovular
  textAlign: 'center',
  width: '60%', // Adjust width to extend it horizontally
  margin: '0 auto', // Center the header
}));

const WelcomeText = styled(Typography)`
  font-size: 12px; // Adjust the font size to be smaller
  font-family: 'Rubik', sans-serif; // Use the Rubik font
  color: white;
  text-align: center;
  margin: 20px auto;
  max-width: 80%; // Ensure it doesn't span more than 80% of the container
  background: -webkit-linear-gradient(#eee, #333); // Fallback for older browsers
  background: linear-gradient(to right, #fff, #ffe); // Gradient effect if supported
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const ChatWindow = styled('div')(({ theme }) => ({
  flex: 1,
  overflowY: 'auto',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.background.default,
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.secondary.main,
    borderRadius: '4px',
  },
}));

const MessageBubble = styled('div')(({ theme, sender }) => ({
  padding: theme.spacing(1, 2),
  marginBottom: theme.spacing(2),
  maxWidth: '80%',
  borderRadius: sender === 'user' ? '20px 20px 0 20px' : '20px 20px 20px 0',
  alignSelf: sender === 'user' ? 'flex-end' : 'flex-start',
  background: sender === 'user' 
              ? 'linear-gradient(to right, #B4B8BB, #7C8183)' // Gradient for user messages
              : 'linear-gradient(to right, #0080bf, #51C4EF)', // Gradient for chatbot messages
  color: sender === 'user' ? theme.palette.primary.contrastText : theme.palette.text.primary,
}));

const Timestamp = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  color: theme.palette.text.secondary,
  marginTop: theme.spacing(0.25),
  marginBottom: theme.spacing(1), // Increase the bottom margin
  textAlign: 'right',
}));

const FullHeightContainer = styled(Container)(({ theme }) => ({
  background: 'linear-gradient(to bottom, #000000, #7F8C8D)', // Apply the gradient background Old background: B9BEC2
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const InputForm = styled('form')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center', // Center the form horizontally
  alignItems: 'center',
  backgroundColor: 'transparent', // Lighter shade of charcoal
  borderRadius: '20px',
  padding: '5px 10px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  width: '100%', // Fixed width, adjust as needed
  margin: '0 auto', // Center the form horizontally
  marginBottom: theme.spacing(2),
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  flexGrow: 1,
  width: '100%', // Ensure the TextField takes up the full width of the form
  '& .MuiOutlinedInput-root': {
    borderRadius: '20px',
    background: 'linear-gradient(to right, #B1B4B6, #7C8183)',
    color: theme.palette.text.primary,
    '& fieldset': {
      border: 'none', // Remove the outer ring
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '10px 20px', // Adjust padding for height
    color: theme.palette.text.primary,
  },
  '& .MuiInputBase-input::placeholder': {
    color: '#8F8F8F',
  },
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  marginLeft: theme.spacing(1),
}));

function App() {
  const [isTyping, setIsTyping] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [socket, setSocket] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const chatWindowRef = useRef(null);

  const [showPrompts, setShowPrompts] = useState(true);

  const prompts = [
    { text: "What type of questions can I ask you?" },
    { text: "What is David's GPA?" },
    { text: "Where is David's mom from?" },
    { text: "What is David's favorite food?" }
  ];

  useEffect(() => {
    const socketUrl = 'https://wwwdgpt.com';
    const newSocket = io(socketUrl, { path: '/socket.io' });
    setSocket(newSocket);

    newSocket.on('message', (message) => {
      setIsTyping(false); // Set typing to false when a message is received
      setMessages((prevMessages) => [...prevMessages, { ...message, timestamp: new Date() }]);
      setIsLoading(false);
    });

    newSocket.on('error', (errorMessage) => {
      setError(errorMessage);
      setIsLoading(false);
    });

    return () => newSocket.close();
  }, []);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  const sendMessage = (messageText) => {
    if (messageText && socket) {
      setShowPrompts(false);
      setIsLoading(true);
      setIsTyping(true); // Set typing to true when the user sends a message
      const message = { text: messageText, sender: 'user', timestamp: new Date() };
      socket.emit('message', message);
      setMessages((prevMessages) => [...prevMessages, message]);
      setInputMessage('');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendMessage(inputMessage);
    }
  };

  const handlePromptClick = (question) => {
    sendMessage(question);
  };

  const profileImageUrl = process.env.PUBLIC_URL + '/Images/DGmini.jpg';

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <CssBaseline />
      <FullHeightContainer maxWidth="md">
        <ProfilePictureContainer>
          <ProfileImage src={profileImageUrl} alt="Profile Picture" />
        </ProfilePictureContainer>
        <Header>
          <Typography variant="h4" style={{ fontFamily: 'Roboto Slab, serif' }}>
            D-GPT
          </Typography>
        </Header>
        {showPrompts ? (
          <PromptContainer>
            <PromptRow>
              {prompts.slice(0, 2).map((prompt, index) => (
                <Prompt key={index} onClick={() => handlePromptClick(prompt.text)}>
                  {prompt.text}
                </Prompt>
              ))}
            </PromptRow>
            <PromptRow>
              {prompts.slice(2, 4).map((prompt, index) => (
                <Prompt key={index} onClick={() => handlePromptClick(prompt.text)}>
                  {prompt.text}
                </Prompt>
              ))}
            </PromptRow>
            <WelcomeText variant="body1">
              Welcome to Chat DGPT! Ask me questions you would like to know about David Green, and I will do my very best to answer them :)
            </WelcomeText>
          </PromptContainer>
        ) : (
          <ChatWindow ref={chatWindowRef}>
            <List>
              {messages.map((message, index) => (
                <ListItem key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: message.sender === 'user' ? 'flex-end' : 'flex-start' }}>
                  <MessageBubble sender={message.sender}>
                    {message.text}
                  </MessageBubble>
                  <Timestamp>
                    {new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                  </Timestamp>
                </ListItem>
              ))}
              {isTyping && (
                <ListItem sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <MessageBubble sender="bot">
                    <TypingDots>
                      <span></span>
                      <span></span>
                      <span></span>
                    </TypingDots>
                  </MessageBubble>
                </ListItem>
              )}
            </List>
          </ChatWindow>
        )}
        <InputForm onSubmit={(e) => { e.preventDefault(); sendMessage(inputMessage); }}>
          <CustomTextField
            variant="outlined"
            placeholder="Ask me anything about David!"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <CustomIconButton color="secondary" onClick={() => sendMessage(inputMessage)} disabled={isLoading || !inputMessage}>
            <SendIcon style={{ color: inputMessage ? theme.palette.text.primary : '#9DA2A5' }} />
          </CustomIconButton>
        </InputForm>
        <Snackbar open={error !== null} autoHideDuration={6000} onClose={() => setError(null)}>
          <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      </FullHeightContainer>
    </ThemeProvider>
  );
}

export default App;
